










































































































































.wrapper-preview .preview {
  max-height: unset !important;
  padding: 0 !important;
  overflow: hidden !important;
}
